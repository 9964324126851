import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

const LoginView = lazy(() => import('../LoginView'));
const HomePage = lazy(() => import('../Components/HomePage'));
const SpecialEventManagement = lazy(() => import('../SpecialEventManagement/SpecialEventManagement'));
const UserManagement = lazy(() => import('../CommandCenterManagement/UserManagement/UserManagement'));
const TeamManagement = lazy(() => import('../CommandCenterManagement/TeamManagement/TeamManagement'));
const GymManagement = lazy(() => import('../CommandCenterManagement/GymManagement/GymManagement'));
const Queries = lazy(() => import('../Queries/Queries'));
const Dashboards = lazy(() => import('../Dashboards'));
const UserCommandCenter = lazy(() => import('../CommandCenterManagement/CommandCenter/User/UserCommandCenter'));
const TeamCommandCenter = lazy(() => import("../CommandCenterManagement/CommandCenter/Team/TeamCommandCenter"));
const UserInboxCommandCenter = lazy(() => import("../CommandCenterManagement/CommandCenter/UserInbox/UserInboxCommandCenter"));
const GymCommandCenter = lazy(() => import('../CommandCenterManagement/CommandCenter/Gym/GymCommandCenter'));
const KpiDashboard = lazy(() => import('../KPIS/KpiDashboard'));
const BugsDashboard = lazy(() => import('../BugReporting/BugsDashboard'));
const Tasks = lazy(() => import('../Tasks/Tasks'));
const HelpfulLinks = lazy(() => import('../HelpfulLinks'));
const MainPerformanceMonitoringDashboard = lazy(() => import('../GamePerformanceMonitoring/MainPerformanceMonitoringDashboard'));
const DailyReport = lazy(() => import('../DailyReport/DailyReport'));
const Chat = lazy(() => import('../chatwithai/chatwithai'));
const BugReporting = lazy(() => import('../BugReporting/BugReporting'));
const BoothVolumeBarCharts = lazy(() => import('../BoothVolumes'));
const MasterCommandCenter = lazy(() => import('../MasterCommandCenter/MasterCommandCenter'));
const SessionsByGameVersionOrBoothToken = lazy(() => import('../GamePerformanceMonitoring/SessionsByGameVersionOrBoothToken'));
const SessionsBySessionId = lazy(() => import('../GamePerformanceMonitoring/SessionsBySessionId'));
const AllSessionsByGameVersionOrBoothToken = lazy(() => import('../GamePerformanceMonitoring/AllSessionsByGameVersionOrBoothToken'));
const FpsChartBySession = lazy(() => import('../GamePerformanceMonitoring/FPSChartBySession'));
const BarChartByVersionOrToken = lazy(() => import('../GamePerformanceMonitoring/BarChartByVersionOrToken'));
const ComparisonBarCharts = lazy(() => import('../GamePerformanceMonitoring/ComparisonBarCharts'));
const CompareTwoGameVersionsOrBoothTokensDashboard = lazy(() => import('../GamePerformanceMonitoring/CompareTwoGameVersionsOrBoothTokensDashboard'));
const SpikeCountComparisonBarCharts = lazy(() => import('../GamePerformanceMonitoring/SpikeCountComparisonBarCharts'));
const SpikeCountSessionsBarChart = lazy(() => import('../GamePerformanceMonitoring/SpikeCountBarChartByVersionOrToken'));
const NotFoundPage = lazy(() => import('../Components/NotFoundPage'));
const IncidentReporting  = lazy(() => import('../IncidentReporting/IncidentReporting'));
const CommandCenterRouter  = lazy(() => import('../Components/CommandCenterRouter.mjs'));

const AppRoutes = () => (
    <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                    <Route path="/login" element={<LoginView />} />
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                    <Route path="/special-event-management" element={<ProtectedRoute><SpecialEventManagement /></ProtectedRoute>} />
                    <Route path="/command-center-router" element={<ProtectedRoute><CommandCenterRouter /></ProtectedRoute>} />
                    <Route path="/user-management" element={<ProtectedRoute><UserManagement /></ProtectedRoute>} />
                    <Route path="/team-management" element={<ProtectedRoute><TeamManagement /></ProtectedRoute>} />
                    <Route path="/gym-management" element={<ProtectedRoute><GymManagement /></ProtectedRoute>} />
                    <Route path="/queries" element={<ProtectedRoute><Queries /></ProtectedRoute>} />
                    <Route path="/dashboards" element={<ProtectedRoute><Dashboards /></ProtectedRoute>} />
                    <Route path="/user-command-center" element={<ProtectedRoute><UserCommandCenter /></ProtectedRoute>} />
                    <Route path="/user-command-center/:searchType/:searchValue" element={<UserCommandCenter />} />
                    <Route path="/gym-command-center" element={<ProtectedRoute><GymCommandCenter/></ProtectedRoute>} />
                    <Route path="/team-command-center" element={<ProtectedRoute><TeamCommandCenter/></ProtectedRoute>} />
                    <Route path="/kpis" element={<ProtectedRoute><KpiDashboard /></ProtectedRoute>} />
                    <Route path="/kpis/:gymId" element={<ProtectedRoute><KpiDashboard /></ProtectedRoute>} />
                    <Route path="/bugs" element={<ProtectedRoute><BugsDashboard /></ProtectedRoute>} />
                    <Route path="/tasks" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
                    <Route path="/helpful-links" element={<ProtectedRoute><HelpfulLinks /></ProtectedRoute>} />
                    <Route path="/main-performance-monitoring-dashboard" element={<ProtectedRoute><MainPerformanceMonitoringDashboard /></ProtectedRoute>} />
                    <Route path="/hero-trainer-daily-report" element={<ProtectedRoute><DailyReport /></ProtectedRoute>} />
                    <Route path="/chatwithai" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
                    <Route path="/bug-reporting" element={<ProtectedRoute><BugReporting /></ProtectedRoute>} />
                    <Route path="/incident-reporting" element={<ProtectedRoute><IncidentReporting /></ProtectedRoute>} />
                    <Route path="/booth-volume-display" element={<ProtectedRoute><BoothVolumeBarCharts /></ProtectedRoute>} />
                    <Route path="/master-command-center" element={<ProtectedRoute><MasterCommandCenter /></ProtectedRoute>} />
                    <Route path="/sessions-by-game-version-or-booth-token" element={<ProtectedRoute><SessionsByGameVersionOrBoothToken /></ProtectedRoute>} />
                    <Route path="/sessions-by-session-id" element={<ProtectedRoute><SessionsBySessionId /></ProtectedRoute>} />
                    <Route path="/all-sessions-by-game-version-or-booth-token" element={<ProtectedRoute><AllSessionsByGameVersionOrBoothToken /></ProtectedRoute>} />
                    <Route path="/fps-chart-by-session" element={<ProtectedRoute><FpsChartBySession /></ProtectedRoute>} />
                    <Route path="/bar-chart-by-index" element={<ProtectedRoute><BarChartByVersionOrToken /></ProtectedRoute>} />
                    <Route path="/compare-bar-charts" element={<ProtectedRoute><ComparisonBarCharts /></ProtectedRoute>} />
                    <Route path="/compare-two-versions-or-booth-tokens-dashboard" element={<ProtectedRoute><CompareTwoGameVersionsOrBoothTokensDashboard /></ProtectedRoute>} />
                    <Route path="/compare-spike-count-bar-charts" element={<ProtectedRoute><SpikeCountComparisonBarCharts /></ProtectedRoute>} />
                    <Route path="/spike-count-by-index" element={<ProtectedRoute><SpikeCountSessionsBarChart /></ProtectedRoute>} />
                    <Route path="/user-inbox" element={<ProtectedRoute><UserInboxCommandCenter /></ProtectedRoute>} />
                    <Route path="/user-inbox/userName/:userName" element={<ProtectedRoute><UserInboxCommandCenter /></ProtectedRoute>} />
                    <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
    </Suspense>
);

export default AppRoutes;