import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from "../auth";
import '../Styles/Header.css';
const logoImage = require('../ImageAssets/BBVR.webp');

function Header() {
    const { user, logout } = useAuth();

    return (
        <header className="app-header">
            <div className="user-info">
                {user && (
                    <div className="user-details">
                        <img src={user.picture} alt="User" className="user-avatar" />
                        <span className="user-name">{user.name}</span>
                    </div>
                )}
                <nav className="main-menu">
                    <Link to="/" className="menu-button">Home</Link>
                    <Link to="/special-event-management" className="menu-button">Special Event Management</Link>
                    <Link to="/main-performance-monitoring-dashboard" className="menu-button">Performance Monitoring</Link>
                    <Link to="/team-management" className="menu-button">Team Management</Link>
                    <Link to="/bug-reporting" className="menu-button">Report a Bug</Link>
                    <Link to="/queries" className="menu-button">Queries</Link>
                    <Link to="/dashboards" className="menu-button">Dashboards</Link>
                    <Link to="/tasks" className="menu-button">Tasks</Link>
                    <Link to="/helpful-links" className="menu-button">Helpful Links</Link>
                    <Link to="/command-center-router" className="menu-button">Command Centers</Link>
                    <button onClick={logout} className="menu-button" aria-label="Logout">Logout</button>
                </nav>
            </div>
            <div className="logo-container">
                <img src={logoImage} alt="BBVR Logo" className="logo" />
            </div>
        </header>
    );
}

Header.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        picture: PropTypes.string,
    }),
    logout: PropTypes.func.isRequired,
};

export default Header;